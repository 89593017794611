<template lang="pug">
div
  v-container(fluid)
    DataFilter(
      :filterProps="{ includeSearch: true, items: [] }"
      :items="datasetNotificacoes"
      :paginate="true"
    )
      template( v-slot:actions )
          v-divider(vertical)
          div(style="width: 200px" class="ml-2 mr-2")
            v-select(
              outlined
              dense
              :items="['Pendentes', 'Pendentes + Finalizadas']"
              hide-details
              item-text="text"
              item-value="value"
              label="Status"
              v-model="selectedItem.statusbase"
            )

          v-btn(
            color="primary"
            fab
            small
            @click="getNotificacoes()"
          )
            v-icon mdi mdi-reload

      template( v-slot:default="{ items }" )
          v-data-table(
            dense
            disable-pagination
            hide-default-footer
            item-key="id"
            :headers="dataHeaders"
            :items="items"
          )
            template( v-slot:item.tipo="{ item }" )
              v-chip(
                :color="item.tipo == 'SUCESSO' ? 'success' : item.tipo == 'AVISO' ? 'warning' : 'error'"
                dark
                small
              )
                | {{ item.tipo }}

            template( v-slot:item.actions="{ item }" )
              v-tooltip(bottom)
                template(v-slot:activator="{ on }" )
                  v-icon(
                    v-on="on"
                    v-if="item.fechado == 1"
                    color="success"
                    small
                  ) mdi-check-all
                  v-icon(
                    v-on="on"
                    v-else
                    color="warning"
                    small
                    @click="dialogFinalizar = true; itemFinalizar = item"
                  ) mdi-receipt-clock-outline
                | {{ item.fechado == 1 ? 'Finalizado' : 'Pendente' }}

    v-dialog(v-model="dialogFinalizar" max-width="500px")
      v-card
        v-card-title
          h5 Notificação
        v-card-text
            p(style="text-align: center") Notificação pendente já foi resolvida?
        v-card-actions(class="pb-4")
          v-spacer
          v-btn(
            outlined
            color="primary"
            @click="dialogFinalizar = false"
            small
          )
            v-icon(left) mdi mdi-close
            | Cancelar
          v-btn(
            outlined
            color="success"
            @click="finalizarNotificacao()"
            small
          )
            v-icon(left) mdi mdi-check
            | Finalizar
          v-spacer

</template>

<script>
import csapi from '@/api/csapi';
import { mapMutations } from 'vuex';
import { state } from '@/socket';

export default {
  data() {
    return {
      selectedItem: {
        statusbase: 'Pendentes',
      },
      dialogFinalizar: false,
      itemFinalizar: {},
      datasetNotificacoes: [],
      dataHeaders: [],
      dataHeadersPendentes: [
        { text: 'Data', value: 'data', sortable: true },
        { text: 'Origem', value: 'origem', sortable: true },
        { text: 'Título', value: 'titulo', sortable: true },
        { text: 'Mensagem', value: 'mensagem', sortable: false },
        {
          text: 'Tipo', value: 'tipo', align: 'center', width: '150', sortable: true,
        },
        {
          text: '#', value: 'actions', align: 'center', width: '80', sortable: false,
        },
      ],
      dataHeadersFinalizada: [
        { text: 'Data', value: 'data', sortable: true },
        { text: 'Origem', value: 'origem', sortable: true },
        { text: 'Título', value: 'titulo', sortable: true },
        { text: 'Mensagem', value: 'mensagem', sortable: false },
        { text: 'Finalizado', value: 'datahorafechado', sortable: false },
        { text: 'Usuário', value: 'nomeusuariofechado', sortable: false },
        {
          text: 'Tipo', value: 'tipo', align: 'center', width: '150', sortable: true,
        },
        {
          text: '#', value: 'actions', align: 'center', width: '80', sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapMutations('app', ['setTitle', 'isControl']),
    ...mapMutations('loader', ['showLoader', 'hideLoader']),

    async fetchData(url, errorMessage) {
      try {
        const res = await csapi.get(url);
        if (res.status === 200) return res.data;
      } catch (error) {
        console.error(error);
        this.$toast.error(`${errorMessage}: ${error.message}`);
      }
    },

    async getNotificacoes() {
      this.showLoader('Carregando Notificações...');
      const link = this.selectedItem.statusbase == 'Pendentes' ? '/v1/alerta?incluir-fechados=false' : '/v1/alerta?incluir-fechados=true';
      let data = await this.fetchData(link, 'Erro ao carregar notificações');

      data = data.map((item) => {
        item.data = new Date(item.data).toLocaleString();
        item.datahorafechado = item.datahorafechado ? new Date(item.datahorafechado).toLocaleString() : null;
        return item;
      });

      data.reverse();

      this.dataHeaders = this.selectedItem.statusbase == 'Pendentes' ? this.dataHeadersPendentes : this.dataHeadersFinalizada;
      this.datasetNotificacoes = data;
      this.hideLoader();
    },

    finalizarNotificacao() {
      this.dialogFinalizar = false;

      const { login, name } = JSON.parse(JSON.stringify(this.$store.state.auth.user));

      csapi.post(`/v1/alerta/${this.itemFinalizar.id}/fechar`,
        {
          nomeusuario: name,
          loginusuarioo: login,
        })
        .then((res) => {
          this.getNotificacoes();
          this.$toast.success('Notificação finalizada com sucesso!');
        })
        .catch((err) => {
          console.error('Erro: ', err);
          this.$toast.error('Erro ao finalizar notificação!');
        });
    },

    atualizaData(data) {
      this.datasetNotificacoes.push(data);
    },
  },
  mounted() {
    this.isControl(false);
    this.setTitle('Central de Notificações');
    this.getNotificacoes();

    state.notificationStatus = false;
  },
};
</script>
