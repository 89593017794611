var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('DataFilter',{attrs:{"filterProps":{ includeSearch: true, items: [] },"items":_vm.datasetNotificacoes,"paginate":true},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-divider',{attrs:{"vertical":""}}),_c('div',{staticClass:"ml-2 mr-2",staticStyle:{"width":"200px"}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":['Pendentes', 'Pendentes + Finalizadas'],"hide-details":"","item-text":"text","item-value":"value","label":"Status"},model:{value:(_vm.selectedItem.statusbase),callback:function ($$v) {_vm.$set(_vm.selectedItem, "statusbase", $$v)},expression:"selectedItem.statusbase"}})],1),_c('v-btn',{attrs:{"color":"primary","fab":"","small":""},on:{"click":function($event){return _vm.getNotificacoes()}}},[_c('v-icon',[_vm._v("mdi mdi-reload")])],1)]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('v-data-table',{attrs:{"dense":"","disable-pagination":"","hide-default-footer":"","item-key":"id","headers":_vm.dataHeaders,"items":items},scopedSlots:_vm._u([{key:"item.tipo",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.tipo == 'SUCESSO' ? 'success' : item.tipo == 'AVISO' ? 'warning' : 'error',"dark":"","small":""}},[_vm._v(_vm._s(item.tipo))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.fechado == 1)?_c('v-icon',_vm._g({attrs:{"color":"success","small":""}},on),[_vm._v("mdi-check-all")]):_c('v-icon',_vm._g({attrs:{"color":"warning","small":""},on:{"click":function($event){_vm.dialogFinalizar = true; _vm.itemFinalizar = item}}},on),[_vm._v("mdi-receipt-clock-outline")])]}}],null,true)},[_vm._v(_vm._s(item.fechado == 1 ? 'Finalizado' : 'Pendente'))])]}}],null,true)})]}}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogFinalizar),callback:function ($$v) {_vm.dialogFinalizar=$$v},expression:"dialogFinalizar"}},[_c('v-card',[_c('v-card-title',[_c('h5',[_vm._v("Notificação")])]),_c('v-card-text',[_c('p',{staticStyle:{"text-align":"center"}},[_vm._v("Notificação pendente já foi resolvida?")])]),_c('v-card-actions',{staticClass:"pb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){_vm.dialogFinalizar = false}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi mdi-close")]),_vm._v("Cancelar")],1),_c('v-btn',{attrs:{"outlined":"","color":"success","small":""},on:{"click":function($event){return _vm.finalizarNotificacao()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi mdi-check")]),_vm._v("Finalizar")],1),_c('v-spacer')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }